<template>
  <div id="page_not_found">
    <section class="bg-cl-secondary py35 px20">
      <div class="container">
        <h2>
          {{ "We can't find the page" }}
        </h2>
      </div>
    </section>
    <section class="bg-cl-primary py35 px20">
      <div class="container">
        <div class="lh16 h5 weight-400">
          <p>Unfortunately we can't find the page you are looking for.</p>
          <p>Click <a href="/">here</a> to return to the home page</p>
          <br>
          <p>OR</p>
          <br>
          <p>If you need assistance, please drop us a line at <a href="mailto:help@couturme.com">help@couturme.com</a>.</p>
          <p>Thank you</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  computed: {
  },
  methods: {
    toggleSearchpanel () {
      this.$store.commit('ui/setSearchpanel', true)
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
